import React from "react"
import ek from "../../static/ek.jpg"
import petnat from "../../static/petnat.jpg"
import logo from "../../static/uddacider_apple.jpg"
import uddaText from "../../static/udda_text.jpg"
import "./index.css"

const Ek = () => (
  <a href="https://www.systembolaget.se/produkt/cider-blanddrycker/udda-cider-3942801/">
    <img className="object-contain" src={ek}></img>
  </a>
)

const Petnat = () => (
  <a href="https://www.systembolaget.se/produkt/cider-blanddrycker/udda-cider-3201101/">
    <img className="object-contain" src={petnat}></img>
  </a>
)

const Contact = () => (
  <div className="mt-12" style={{ padding: "20px" }}>
    <h3>Kontakt</h3>
    <a href="mailto:anton@uddacider.se">anton@uddacider.se</a>
  </div>
)

export default function Home() {
  return (
    <>
      {process.env.NODE_ENV === "production" && (
        <head>
          <script
            defer
            src="https://cloud.umami.is/script.js"
            data-website-id="e3467769-d143-4545-ad1f-8998d20f296b"
          ></script>
        </head>
      )}
      <header className="container text-center py-14 mx-auto">
        <img src={logo} alt="logo" className="mx-auto max-w-48" />
        <img src={uddaText} alt="logo" className="mx-auto max-w-48" />
      </header>
      <h1 className="text-3xl font-garamond p-2">
        Vildjäst cider från Stockholm på 100% frukt
      </h1>

      {/* <img src={apple} alt="apple" className="my-10 max-w-md mx-auto" /> */}
      <div className="flex flex-row items-start max-w-screen-xl mx-auto flex-wrap">
        <div className="mt-20 max-w-md mx-auto font-garamond text-left grid grid-cols-4 gap-4 p-4">
          <div className="col-span-3">
            <h2 className="text-3xl">Udda Cider - Ek 2021</h2>
            <p className="mt-2 text-lg">
              Torr hantverkscider på KRAV-äpplen från{" "}
              <a href="https://www.jonslundsapplet.se/">Jonslund</a> i
              Västergötland. Äpplena pressades i oktober 2021. Vildjäst och
              lagrad i 12 månader på gamla ekfat på som tidigare har lagrat
              iscider. Buteljerad med jäsande cider hösten 2022 för en andra
              jäsning på flaska.
              <br />
              <br />
              Äppelsorter: Berner Rosen, Paradisäpplen
              <br />
              <br />
              <a
                className="underline"
                data-umami-event="systembolaget_ek"
                href="https://www.systembolaget.se/produkt/cider-blanddrycker/udda-cider-3942801/"
              >
                Beställningsbar på Systembolaget
              </a>
            </p>
          </div>
          <Ek />
        </div>

        <div className="mt-20 max-w-md mx-auto font-garamond text-left grid grid-cols-4 gap-4 p-2">
          <div className="col-span-3">
            <h2 className="text-3xl">Udda Cider - Pet nat 2021</h2>
            <p className="mt-2 text-lg">
              Torr hantverkscider på äpplen från Ekerö, Roslagen, Juleboda i
              Skåne och Jonslund i Västergötland. Äpplena pressades i oktober
              och november 2021. Vildjäst i ca 3 månader. Buteljerad innan allt
              socker jäst ut för avslutande jäsning på flaska.
              <br />
              <br />
              Sorter: Oberländer, Cox Orange, Santana, Rewana, Kim, Borgherre,
              Fårnos, Signe Tillisch, Vildäpplen, Vildpäron
              <br />
              <br />
              <a
                className="underline"
                data-umami-event="systembolaget_petnat"
                href="https://www.systembolaget.se/produkt/cider-blanddrycker/udda-cider-3201101/"
              >
                Tillgänlig på Systembolaget Sickla och Nacka Forum
              </a>
            </p>
          </div>
          <Petnat />
        </div>
      </div>

      <Contact />
    </>
  )
}
